import { anchorScrollTo, blockInView, anchorTagMenuScroll } from "./barba";

( function( $ ) {

    $( function() {

        $('section[data-product-id]').each(function() {

            let productID = $(this).attr('data-product-id');
            let anchorID = '#'+productID;
            let hash = window.location.hash;

            if(anchorID === hash) {
                let elementID = $(this).attr('id');
                setTimeout(function () {
                    anchorScrollTo(elementID);
                }, 750);
            }

        });

        // if($('.js-anchorTagMenu').length > 0) {
        //     $('.js-anchorTagMenu').parent().addClass('-full');
        //     anchorTagMenuScroll();
        // }

    });

} ) ( jQuery );