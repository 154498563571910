import { anchorScrollTo } from "./barba";

( function( $ ) {

    $( function() {

        $('.a-button, p > a').each(function() {
            $(this).on('click', function(e) {
                var hrefValue = $(this).attr('href');
                if (hrefValue && hrefValue.includes('#')) {
                    e.preventDefault();
                    let productID = hrefValue.substring(1);
                    setTimeout(function () {
                        anchorScrollTo(productID);
                    }, 350);
                }
            });
        });

        $('.js-removeBlockID').each(function() {
            $(this).on('click', function() {
                $(this).parent().remove();
            });
        });

    });

} ) ( jQuery );