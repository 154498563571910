( function( $ ) {

	$( function() {

        $('.js-dealerLogin').on('click', '', function(e){
            e.preventDefault();
            $('#js-loginButtons').hide()
            $('#js-loginDealer').show();
        });

    } );

} ) ( jQuery );