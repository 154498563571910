import { closeModal, openConfigurator, openForm, openDownload, openSignup, setCookieRedirectUser, modalMessageRedirect } from "./barba";

( function( $ ) {

	$( function() {

        modalMessageRedirect();

        $('.js-openConfigurator').on('click', function(e){
            e.preventDefault();
            
            if( $(this).hasClass('o-navigation__button') ) {
                $(this).parents('.o-navigation__item').removeClass('-active');
                $('.js-hamburger').removeClass('-active');
                $('.o-navigation').removeClass('-active');
                $('body, html').removeClass('-overflow');
            }

            // Open configurator
            openConfigurator();
        });

        $('.js-openForm').on('click', function(e){
            e.preventDefault();

            let form_id = $(this).attr('data-form-id') ? $(this).attr('data-form-id') : null,
            form_title = $(this).attr('data-form-title') ? $(this).attr('data-form-title') : null,
            product_title = $(this).attr('data-product-title') ? $(this).attr('data-product-title') : null;

            // Open form
            openForm(form_id, form_title, product_title);
        });

        $('.js-openDownload').on('click', function(e){
            e.preventDefault();

            let product_id = $(this).closest('.m-featured').attr('data-product-id'),
            product_tax_name = $(this).attr('data-tax-series');

            // Open download
            openDownload(product_id, product_tax_name);
        });

        $('.js-openSignup').on('click', function(e){
            e.preventDefault();

            // Open signup
            openSignup();
        });

        $(document).on('click', '.js-setCookieRedirectUser', function(e){
            e.preventDefault(); // Prevent the default link behavior
            setCookieRedirectUser();
        });

        $(document).on('click', '.js-closeModal:not(.-message)', function(e){
            e.preventDefault();
            closeModal();
        });

    } );

} ) ( jQuery );