import { breakpoints } from "./breakpoints";
import { configuratorInsertForm } from "./barba";

( function( $ ) {

    $( function() {

        $(document).on('click', '.js-configuratorInput', function(e){
            if($(this).find('input').attr('type') == 'checkbox') {
                $(this).toggleClass('-active');
            } else {
                $(this).addClass('-active').siblings().removeClass('-active');
            }
            $('.o-configurator__box').removeClass('-error');
            if($(this).attr('data-name') == 'off-site' || $(this).attr('data-name') == 'externe-lagerung') {
                $(this).closest('.js-configuratorStepItems').addClass('-second');
                $('.js-configuratorStepParent').addClass('-second'),
                $('.js-totalSteps').text('3');
            } else if($(this).attr('data-name') == 'in-house' || $(this).attr('data-name') == 'interne-lagerung' ) {
                $(this).closest('.js-configuratorStepItems').removeClass('-second');
                $('.js-configuratorStepParent').removeClass('-second'),
                $('.js-totalSteps').text('5');
            } else if($(this).attr('data-name') == 'automated-storage' || $(this).attr('data-name') == 'automatisierte-lagerung') {
                $('.js-configuratorStepParent').addClass('-auto').removeClass('-nonAuto');
            } else if($(this).attr('data-name') == 'non-automated-storage' || $(this).attr('data-name') == 'nicht-automatisierte-lagerung') {
                $('.js-configuratorStepParent').addClass('-nonAuto').removeClass('-auto');
            }
        });

        $(document).on('click', '.js-configuratorNext', function(e){
            let parent = $('.js-configuratorStepParent'),
            parentStep = parseInt(parent.attr('data-step')),
            parentStepAppearance = parseInt(parent.attr('data-step-appearance')),
            stepDiff =  parent.hasClass('-second') ? '.js-configuratorStepItems.-second' : '.js-configuratorStepItems:not(.-second)',
            stepCount = parent.find(stepDiff).length,
            activeStep = $('.js-configuratorStep.-active'),
            emptyStep = true,
            plusNumber = 0;
            e.preventDefault();

            activeStep.find('.o-configurator__box').each(function(){
                if($(this).hasClass('-active')) {
                    emptyStep = false;
                }
            });

            if((parentStep >= 1 && parentStep < stepCount && emptyStep == false) || (parentStep == stepCount)) {
                if((parent.hasClass('-nonAuto') && parentStep == 4) || (parent.hasClass('-auto') && parentStep == 3)) {
                    plusNumber = 2;
                } else {
                    plusNumber = 1;
                }
                parent.attr('data-step', parentStep+plusNumber);
                parent.attr('data-step-appearance', parentStepAppearance+1);
                $('.js-stepCount').text(parentStepAppearance+1);
                activeStep.find('.o-configurator__box').each(function(){
                    $(this).removeClass('-error');
                });
                configuratorSteps();
            } else {
                activeStep.find('.o-configurator__box').each(function(){
                    $(this).addClass('-error');
                });
                plusNumber = 0;
            }

            if(stepCount == parentStep+1 && parent.hasClass('-second')) {
                $('.o-modal').addClass('-form');
                $('.js-configuratorNext').addClass('-mute');
                configuratorInsertForm();
            } else {
                $('.o-modal').removeClass('-form');
                $('.js-configuratorNext').removeClass('-mute');
                $('.js-configuratorInsertForm').html('');
            }
            if(stepCount === parentStep && emptyStep == false) {
                parent.trigger('submit');
            }
            if(parentStep == 0) {
                $('.js-configuratorBack').addClass('-mute');
            } else {
                if(plusNumber > 0) {
                    $('.js-configuratorBack').removeClass('-mute');
                }
            }
        });

        $(document).on('bind', 'gform_post_render', function(e){
            e.preventDefault();
            return false;
        });

        $(document).on('click', '.js-configuratorBack', function(e){
            let parent = $('.js-configuratorStepParent'),
            parentStep = parseInt(parent.attr('data-step')),
            parentStepAppearance = parseInt(parent.attr('data-step-appearance')),
            stepCount = parent.find('.js-configuratorStepItems').length,
            minusNumber = 0;
            e.preventDefault();

            $('.o-modal').removeClass('-form');
            $('.js-configuratorNext').removeClass('-mute');

            if(parentStep == 2) {
                $(document).find('.o-configurator__input').each(function(){
                    $(this).prop('checked', false);
                });
            }

            if(parentStep > 1 && parentStep < stepCount) {
                if((parent.hasClass('-nonAuto') && parentStep == 6) || (parent.hasClass('-auto') && parentStep == 5)) {
                    minusNumber = 2;
                } else {
                    minusNumber = 1;
                }
                parent.attr('data-step', parentStep-minusNumber);
                parent.attr('data-step-appearance', parentStepAppearance-1);
                $('.js-stepCount').text(parentStepAppearance-1);
                configuratorSteps();
            }

            if(parentStep == 2) {
                $('.js-configuratorBack').addClass('-mute');
            } else {
                $('.js-configuratorBack').removeClass('-mute');
            }
        });

        configuratorStepsResize();

    });

} ) ( jQuery );

export function configuratorSteps() {
    let parent = $('.js-configuratorStepParent'),
    parentStep = parseInt(parent.attr('data-step')),
    stepDiff = '',
    stepDiffSlick = '';
    if(parent.hasClass('-second')) {
        stepDiff = '.js-configuratorStep.-second, .js-configuratorStep.-first';
        stepDiffSlick = '.js-configuratorStep.-second:not(.-form), .js-configuratorStep.-first';
    } else {
        stepDiff = '.js-configuratorStep:not(.-second)';
        stepDiffSlick = '.js-configuratorStep:not(.-second)';
    }
    $(document).find(stepDiff).each(function(){
        if(parentStep == $(this).attr('data-step')) {
            $(this).addClass('-active').siblings().removeClass('-active');
        }
    });
    $(document).find(stepDiffSlick).each(function(){
        if(window.innerWidth <= breakpoints.viewport_12){
            $(this).find('.js-configuratorStepItems:not(.-form):not(.-single)').slick('resize');
            $(this).find('.js-configuratorStepItems:not(.-form):not(.-single)').slick('setDimensions');
        }
    });
}

export function configuratorStepsResize() {
    let parent = $('.js-configuratorStepParent'),
    parentStep = parseInt(parent.attr('data-step')),
    stepDiff = '',
    stepDiffSlick = '';
    if(parent.hasClass('-second')) {
        stepDiff = '.js-configuratorStep.-second, .js-configuratorStep.-first';
        stepDiffSlick = '.js-configuratorStep.-second:not(.-form), .js-configuratorStep.-first';
    } else {
        stepDiff = '.js-configuratorStep:not(.-second)';
        stepDiffSlick = '.js-configuratorStep:not(.-second)';
    }
    $(window).on('resize orientationchange', function() {
        $(document).find(stepDiffSlick).each(function() {
            if(window.innerWidth <= breakpoints.viewport_12){
                $(this).find('.js-configuratorStepItems:not(.-form):not(.-single)').slick('resize');
            }
        });

        if(window.innerWidth >= breakpoints.viewport_5 && window.innerWidth <= breakpoints.viewport_7){
            $('.o-configurator__inner').css({'opacity' : 0, 'pointer-events' : 'none'});
            $('.o-configurator__rotate').css({'opacity' : 1, 'pointer-events' : 'all'});
        } else {
            $('.o-configurator__inner').css({'opacity' : 1, 'pointer-events' : 'all'});
            $('.o-configurator__rotate').css({'opacity' : 0, 'pointer-events' : 'none'});
        }
    });
}