import { breakpoints } from "./breakpoints";

( function( $ ) {

    $( function() {

        $('.js-featuredDataMore').on('click', function(){
            let data = $(this).prev();
            data.toggleClass('-active');
            $(this).text(
                data.hasClass('-active') ? 'Read less' : 'Read more'
            );
        });

    });

} ) ( jQuery );