import { breakpoints } from "./breakpoints";

( function( $ ) {

    $( function() {

        $('.js-faqToggle').each(function(i) {
            $(this).on('click', function(){
                $(this).parent().toggleClass('-active');
            });
        });
        
    });

} ) ( jQuery );