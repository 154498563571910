import { filterProjects, filterNewsEvents, filterProducts, filterProductsCompare } from "./barba";

( function( $ ) {

	$( function() {

		$('.js-newsEventsFilterBox').on('change', function(){
			var $select_value = $(this).find(":selected").val(),
				params = {
					value: $select_value,
					filter: true
				};
            filterNewsEvents(params);
		});

        $('.js-projectsFilterBox').on('change', function(){
			var $select_value = $(this).find(":selected").val(),
				params = {
					value: $select_value,
					filter: true
				};
            filterProjects(params);
		});

        $('.js-productsFilterBox').on('change', function(){
			var $select_value = $(this).find(":selected").val(),
				params = {
					value: $select_value,
					filter: true
				};
            filterProducts(params);
		});

		$('.js-productsCompareFilterBox').on('change', function(){
			$('.js-productCompareSlider').slick('removeSlide', null, null, true);
			// $('.js-productCompareSlider').html();
			var $select_value = $(this).val(),
			params = {
				value: $select_value,
				filter: true
			};
            filterProductsCompare(params);
		});

    } );

} ) ( jQuery );