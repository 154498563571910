import 'slick-carousel';
import { breakpoints } from "./breakpoints";

( function( $ ) {

    $( function() {

        $(document).find('.js-mediaSlider').each(function(){

            let settings = {
                dots: false,
                arrows: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                rows: 0
            };

            $(this).slick(settings);

            $(this).siblings('.js-sliderPrev').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('.js-mediaSlider').slick("slickPrev");
            });
    
            $(this).siblings('.js-sliderNext').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('.js-mediaSlider').slick("slickNext");
            });

            if(window.innerWidth >= breakpoints.viewport_10) {
                let video = $(this).find('video'),
                    play = $(this).find('.js-videoPlay');

                play.on('click', function(e){
                    e.preventDefault();
                    video.trigger('play');
                    play.addClass('-hide');
                });

                video.on('click', function(e){
                    if(!this.paused){
                        play.removeClass('-hide');
                    } else {
                        play.addClass('-hide');
                    }
                });
            }

        });

        $(document).find('.js-productSlider').each(function(){

            let settings = {
                dots: false,
                arrows: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 0
            };

            let count = $(this).children().length;

            if(count > 1) {
                $(this).slick(settings);
            }

            $(this).siblings('.js-sliderPrev').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('.js-productSlider').slick("slickPrev");
            });
    
            $(this).siblings('.js-sliderNext').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('.js-productSlider').slick("slickNext");
            });

        });

        $(document).find('.js-itemSlider').each(function(){
            let settings = {
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            };

            let count = $(this).children().length;

            if((count > 3 && window.innerWidth >= breakpoints.viewport_5) || window.innerWidth <= breakpoints.viewport_5) {
                $(this).slick(settings);
            }

            $(this).siblings('.js-sliderPrev').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('.js-itemSlider').slick("slickPrev");
            });
    
            $(this).siblings('.js-sliderNext').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('.js-itemSlider').slick("slickNext");
            });

        });

        $(document).find('.js-itemSliderMobile').each(function(){
            let settings = {
                dots: false,
                arrows: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 0
            };

            if(window.innerWidth <= breakpoints.viewport_5) {
                $(this).slick(settings);
            }

            $(this).siblings('.js-sliderPrev').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('.js-itemSliderMobile').slick("slickPrev");
            });
    
            $(this).siblings('.js-sliderNext').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('.js-itemSliderMobile').slick("slickNext");
            });

        });

        $(document).find('.js-postsSlider').each(function(){
            let settings = {
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1.1,
                slidesToScroll: 1,
                rows: 0,
                mobileFirst: true
            };

            if(window.innerWidth <= breakpoints.viewport_5) {
                $(this).slick(settings);
            }
        });
        
    });

} ) ( jQuery );

export function configuratorSlides() {
    $(document).find('.js-configuratorStepItems').not('.slick-initialized, .-form, .-single').each(function(){
        let settings = {
            dots: false,
            arrows: false,
            infinite: false,
            draggable: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            responsive: [
                {
                    breakpoint: 9999,
                    settings: "unslick"
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 1.3
                    }
                }
            ]
        };

        if(window.innerWidth <= breakpoints.viewport_13) {
            $(this).slick(settings);
        }
    });
    if(window.innerWidth <= breakpoints.viewport_13) {
        $(document).on('click', '.js-configuratorInput', function(e) {
            let slideNmbr = $(this).attr('data-slick-index');
            $(this).closest('.js-configuratorStepItems').slick('slickGoTo', slideNmbr);
        });
    }
}