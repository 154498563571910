import { breakpoints } from "./breakpoints";

( function( $ ) {

    $( function() {

        let translateValue = 0;

        $('.js-hamburger').on('click', function(e){
            e.preventDefault();
            $(this).toggleClass('-active');
            $('.o-navigation').toggleClass('-active');
            if($(this).hasClass('-active')) {
                $('.o-navigation').css('transform', 'translateX(0%)');
                translateValue = 0;
            }
            $('body, html').toggleClass('-overflow');
        });

        if(window.innerWidth >= breakpoints.viewport_13) {
            $('.js-navItem, .js-navSubItem').closest('.o-navigation__item').on( 'mouseenter', function() {
                $(this).addClass('-active').siblings().removeClass('-active');
            });
            $('.js-closeMega').on( 'mouseenter', function() {
                $(this).parent().removeClass('-active');
            });
            $('.m-submenu__item').on( 'mouseenter', function() {
                $(this).addClass('-active').siblings().removeClass('-active');
            });
        }

        if(window.innerWidth <= breakpoints.viewport_13) {
            $('.js-navSubItem').on('click', function(e){
                translateValue -= 100;
                if($(this).closest('.o-navigation__item, .m-submenu__item').hasClass('-sub')) {
                    $(this).closest('.o-navigation__item, .m-submenu__item').addClass('-active').siblings().removeClass('-active');
                    $('.o-navigation').css('transform', 'translateX('+translateValue+'%)');
                }
            });

            $('.js-navItemBack').on('click', function(e){
                translateValue += 100;
                e.preventDefault();
                $(this).closest('.o-navigation__item, .m-submenu__item').removeClass('-active');
                $('.o-navigation').css('transform', 'translateX('+translateValue+'%)');
            });
        }
    });

} ) ( jQuery );