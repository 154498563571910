( function( $ ) {

    $( function() {
      
        // Scroll to the top when the button is clicked
        $('.js-backTop').on('click', function() {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        });

    });

} ) ( jQuery );