import { anchorScrollTo, blockInView, anchorTagMenuScroll } from "./barba";

( function( $ ) {

    $( function() {


        if($('.js-flexBlocks').length > 0) {
            const elements = document.querySelectorAll("section");
            elements.forEach((element) => {
                blockInView(element, element.id);
            });
        }

        $('.js-anchorTagTrigger').each(function() {

            let $anchorID = $(this).attr('data-anchor'); 
            $(this).on('click', function(){
                anchorScrollTo($anchorID);
            });

        });

        if($('.js-anchorTagMenu').length > 0) {
            $('.js-anchorTagMenu').parent().addClass('-full');
            anchorTagMenuScroll();
        }

    });

} ) ( jQuery );