export let header_height = 0;
import { breakpoints } from "./breakpoints";

( function( $ ) {

	$( function() {

        if(window.innerWidth >= breakpoints.viewport_10){
            $(window).on('resize', function(){
                resizeHeaderMirror();
            });

            resizeHeaderMirror();

        } else {

            if($('.logged-in').length > 0) {
                // logout button
                $('.js-profileSub').on('click', function(e){
                    e.preventDefault();
                    $(this).toggleClass('-active');
                });
            }

            // language switcher button
            $('.js-langSwitch').on('click', function(e){
                e.preventDefault();
                $(this).parent().toggleClass('-active');
            });

            // hide when clicked anywhere outside the element
            $(document).on('mouseup', function(e) {
                if($('.logged-in').length > 0) {
                    let profileLogout = $('.js-profileSub');
                    if(!profileLogout.is(e.target) && profileLogout.has(e.target).length === 0) {
                        profileLogout.removeClass('-active');
                    }
                }

                let langSwitch = $('.js-langSwitch');
                if(!langSwitch.is(e.target) && langSwitch.has(e.target).length === 0) {
                    langSwitch.parent().removeClass('-active');
                }
            });
        }

    } );

} ) ( jQuery );

export function resizeHeaderMirror(){
    const $ = jQuery;
    let height = $('.js-headerHeight').outerHeight();
    $('.js-headerMirror').css('height', height);
    header_height = $('.js-header');
}