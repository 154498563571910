require('./anchorConfiguratorProduct.js');
require('./anchorTagMenu.js');
require('./backTop.js');
require('./barba.js');
require('./anchorBlockID.js');
require('./breakpoints.js');
require('./configurator.js');
require('./faq.js');
require('./filter.js');
require('./header.js');
require('./login.js');
require('./modal.js');
require('./navigation.js');
require('./product.js');
require('./slider.js');