import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { breakpoints } from "./breakpoints";
import { configuratorSteps } from "./configurator";
import { configuratorSlides } from "./slider";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

let smoother;

$ = jQuery;

( function( $ ) {
	$( function() {

        initSmoother();
        headerScroll();

    });
} ) ( jQuery );

export function initSmoother() {
    if(window.innerWidth >= breakpoints.viewport_11){
        smoother = ScrollSmoother.create({
            normalizeScroll: true,
            ignoreMobileResize: true,
            smoothTouch: false,
            smooth: 0.8,
            effects: true
        });
        smoother.scrollTo(0);
    }
}

export function anchorTagMenuScroll() {
    const headerScrollElement = document.querySelector('.js-anchorTagMenu');

    ScrollTrigger.create({
        trigger: headerScrollElement,
        start: () => "top top",
        end: () => document.documentElement.scrollHeight,
        pin: true, 
        pinSpacing: false 
    });

}

export function headerScroll() {
    const headerScrollElement = document.querySelector('.js-headerScroll');
    const backTopScroll = document.querySelector('.js-backTop');

    if(window.innerWidth >= breakpoints.viewport_11){
        var $topDistance = '+=300px';
    } else {
        var $topDistance = '+=150px';
    }
    const headerScrollTrigger = ScrollTrigger.create({
        markers: false,
        trigger: 'body',
        start: $topDistance,
        onUpdate: self => {
            if (self.direction === 1) {
                headerScrollElement.classList.add('-hide');
                backTopScroll.classList.add('-active');
                $('.o-navigation__item').removeClass('-active');
            } else {
                headerScrollElement.classList.remove('-hide');
            }
        },
        onLeaveBack: () => {
            headerScrollElement.classList.remove('-hide');
            backTopScroll.classList.remove('-active');
        },
    });
}

export function filterProjects(params){
    $('.js-postItems').html('');
    $('.js-loader').show();
    $.post( ajax_url, {
        'action' : 'filter_projects',
        'data' : params,
    }, function( data ) {
        if( data.success == true ) {
            let json = JSON.parse(data.data);
            var content = json['content'];
            $('.js-postItems').html(content);
        }
    }).done( function(){
        $('.js-loader').hide();
    });
}

export function filterNewsEvents(params){
    $('.js-postItems').html('');
    $('.js-loader').show();
    $.post( ajax_url, {
        'action' : 'filter_news_events',
        'data' : params,
    }, function( data ) {
        if( data.success == true ) {
            let json = JSON.parse(data.data);
            var content = json['content'];
            $('.js-postItems').html(content);
        }
    }).done( function(){
        $('.js-loader').hide();
    });
}

export function filterProducts(params){
    $('.js-productsItems').html('');
    $('.js-loader').show();
    $.post( ajax_url, {
        'action' : 'filter_products',
        'data' : params,
    }, function( data ) {
        if( data.success == true ) {
            let json = JSON.parse(data.data);
            var content = json['content'];
            $('.js-productsItems').html(content);
        }
    }).done( function(){
        $('.js-loader').hide();
    });
}

export function filterProductsCompare(params){
    $('.js-loader').show();
    $.post( ajax_url, {
        'action' : 'filter_products_compare',
        'data' : params,
    }, function( data ) {
        if( data.success == true ) {
            let json = JSON.parse(data.data),
                content = json['content'];
            $('.js-productCompareSlider').slick('slickAdd', content);
        }
    }).done( function(){
        $('.js-loader').hide();
        $('.js-productCompareSlider').get(0).slick.setPosition();
    });
}

export function openConfigurator(){
    if(window.innerWidth >= breakpoints.viewport_12){
        smoother.paused(true);
    } else {
        $('body, html').addClass('-overflow');
    }
    $('.js-configuratorStepItems').slick('removeSlide', null, null, true);
    $('.js-modalContent').html('');
    $('.js-loader').show();
    $('.o-modal').addClass('o-configurator -active -loading');
    $.post( ajax_url, {
        'action' : 'open_configurator',
        'data' : true,
    }, function( data ) {
        if( data.success == true ) {
            let json = JSON.parse(data.data),
                content = json['content'];

            $('.js-modalContent').html(content);
            configuratorSlides();
        }
    }).done( function(){
        $('.js-loader').hide();
        $('.o-modal').removeClass('-loading');
        configuratorSteps();
    });
}

export function openForm(form_id, form_title, product_title){
    if(window.innerWidth >= breakpoints.viewport_11){
        smoother.paused(true);
    } else {
        $('body, html').addClass('-overflow');
    }
    $('.js-modalContent').html('');
    $('.js-loader').show();
    $('.o-modal').addClass('o-modal__form -active -loading');
    jQuery.get(ajax_url+'?action=open_form&form_id='+form_id+'&form_title='+form_title+'&product_title='+product_title, function(response){
        if( response != null ) {
            let $this = $('.js-modalContent');
            $this.html(response);
            setTimeout(function () {
                $this.find('.gfield--type-hidden input').val(product_title);
            }, 250); // adds delay just in case
        }
    }).done( function(){
        $('.js-loader').hide();
        $('.o-modal').removeClass('-loading');
    });
}

export function openDownload(product_id, product_tax_name){
    if(window.innerWidth >= breakpoints.viewport_11){
        smoother.paused(true);
    } else {
        $('body, html').addClass('-overflow');
    }
    $('.js-modalContent').html('');
    $('.js-loader').show();
    $('.o-modal').addClass('o-modal__form -active -loading');
     jQuery.get(ajax_url+'?action=open_download_cookie&product_id='+product_id, function(response){
        if( response != null ) {
        }
    }).done( function(){
    });
    setTimeout(function () {
        jQuery.get(ajax_url+'?action=open_download&product_id='+product_id+'&product_tax_name='+product_tax_name, function(response){
            if( response != null ) {
                $('.js-modalContent').html(response);
            }
        }).done( function(){
            $('.js-loader').hide();
            $('.o-modal').removeClass('-loading');
        });
    }, 2000); // adds delay just in case
}

export function openSignup(product_id){
    if(window.innerWidth >= breakpoints.viewport_11){
        smoother.paused(true);
    } else {
        $('body, html').addClass('-overflow');
    }
    $('.js-modalContent').html('');
    $('.js-loader').show();
    $('.o-modal').addClass('o-modal__form -active -loading');
     jQuery.get(ajax_url+'?action=open_signup&product_id='+product_id, function(response){
        if( response != null ) {
            $('.js-modalContent').html(response);
        }
    }).done( function(){
        $('.js-loader').hide();
        $('.o-modal').removeClass('-loading');
    });
}

export function configuratorInsertForm(){
    $('.js-configuratorInsertForm').html('');
    $('.js-loader').show();
     jQuery.get(ajax_url+'?action=configurator_insert_form', function(response){
        if( response != null ) {
            $('.js-configuratorInsertForm').html(response);
        }
    }).done( function(){
        $('.js-loader').hide();
    });
}

export function setCookieRedirectUser(href){
    jQuery.get(ajax_url+'?action=cookie_modal_message_redirect', function(response){
        if( response != null ) {
            $('.js-modalContent').html('');
            $('.o-modal').removeClass().addClass('o-modal');
            if(window.innerWidth >= breakpoints.viewport_11){
                smoother.paused(false);
            } else {
                $('body, html').removeClass('-overflow');
            }
        }
    });
}

export function modalMessageRedirect(href){
    const page_id = $('.o-modal').attr('data-page-id');
    jQuery.get(ajax_url+'?action=modal_message_redirect&page_id='+page_id, function(response){
        if( response != null && response != '' ) {
            openModalMessage();
            $('.js-modalContent').html(response);
            if(window.innerWidth >= breakpoints.viewport_11){
                smoother.paused(false);
            } else {
                $('body, html').removeClass('-overflow');
            }
        }
    });
}

export function closeModal(){
    $('.js-modalContent').html('');
    $('.o-modal').removeClass().addClass('o-modal');
    if(window.innerWidth >= breakpoints.viewport_11){
        smoother.paused(false);
    } else {
        $('body, html').removeClass('-overflow');
    }
}

export function anchorScrollTo($id){
    if(window.innerWidth >= breakpoints.viewport_10){
        gsap.to(smoother, {
            scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.offset('#'+$id, 'top 100px')),
            duration: 1
        });
    } else {
        scrollToMobile($id);
    }
}

function scrollToMobile($id) {
    var target = $('#'+$id);
    // Does a scroll target exist?
    if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
            scrollTop: target.offset().top
        }, 2000, function() {
            // Callback after animation
            // Must change focus!
            var $target = $(this);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
                return false;
            } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
            };
        });
    }
}

export function blockInView(element, blockID) {
    ScrollTrigger.create({
      trigger: element,
      start: "top center",
      end: "bottom center",
      onEnter: () => {
        $('.js-anchorTag').each(function() {
            if($(this).find('.js-anchorTagTrigger').attr('data-anchor') == blockID) {
                $(this).addClass('-active').siblings().removeClass('-active');
            }
        });
        // code to be executed when element enters view
      },
      onEnterBack: () => {
        $('.js-anchorTag').each(function() {
            if($(this).find('.js-anchorTagTrigger').attr('data-anchor') == blockID) {
                $(this).addClass('-active').siblings().removeClass('-active');
            }
        });  
      },
      onLeaveBack: () => {
        $('.js-anchorTag').each(function() {
            if($(this).find('.js-anchorTagTrigger').attr('data-anchor') == blockID) {
                $(this).addClass('-active').siblings().removeClass('-active');
            }
        });
      }
    });
}

export function openModalMessage() {
    $('.o-modal').addClass('-message').addClass('-active');
    $('.o-modal .a-overlay').addClass('-message');
    if(window.innerWidth >= breakpoints.viewport_11){
        smoother.paused(true);
    } else {
        $('body, html').addClass('-overflow');
    }
}