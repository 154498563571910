export const breakpoints = {
    xxxs: 0,
    viewport_3: 320,
    viewport_4: 480,
    viewport_5: 568,
    viewport_6: 667,
    viewport_7: 768,
    viewport_8: 812,
    viewport_9: 992,
    viewport_10: 1025,
    viewport_11: 1150,
    viewport_12: 1280,
    viewport_13: 1370,
    viewport_14: 1440,
    viewport_15: 1500,
    viewport_16: 1600,
    viewport_19: 1920
};